.management-status-bar {
	width: calc(100% - 40px);
	padding: 10px 20px;
	font-weight: 500;
}

.green {
	background: #b6f2d9;
}

.red {
	background: #f8c8c8;

	span {
		color: #c30e0e;
	}
}

.gathering-management-grid-icon-conatiner {
	background: #fff;
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;

	cursor: pointer;

	&.rigth {
		float: right;
		margin-right: 10px;
	}

	&.left {
		float: left;
	}
	&.center {
		margin-left: 10px;
	}

	&.primary-color {
		border: 1px solid #0f5172;
		color: #0f5172;
	}
}

.select-people-btn-save-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
	margin-bottom: 20px;

	button {
		margin-right: 20px;
	}
}

.people-selected-checkbox {
	width: 25px;
	height: 25px;
	cursor: pointer;
}

.people-selected-checkbox:checked {
	background-color: pink;
}

.force-capitalize {
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}
