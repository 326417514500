.contact-popover-container{
    margin-top: -95px; 
    margin-left: -60px;
    padding: 15px;
    position: absolute; 
    width: auto; 

    background:#fff;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    p{
        margin: 0;
    }

    .contact-popover-arrow{
        width: 15px;
        height: 15px;
        background:#fff;
        position: absolute;
        margin-left: 50px;
        transform: rotate(45deg);
        margin-top: 5px;
        
    }
}