/*box*/

.box-user {
    position: absolute;
    width: 20%;
    height: 83px;
    background: #E7E7ED;
    padding: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.box-status {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    margin-top: 3px;
}

/* user information */

.user-img {
    padding-left: 20px;
    margin-top: 10px;
    text-align: center;
}

.user {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 500;
    color: #7C7C7C;
    padding-left: 19px;
}

.user-time {
    display: flex;
    align-items: center;
    margin-top: 9px;
    margin-right: 30px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

/*status */

.box-status .status {
    width: 6%;
    height: 20px;
    background: #B6F2D9;
    text-align: center;
    margin-top: 12px;
    margin-right: 48px;
    margin-left: auto;
}

.status-change {
    background: #FFF4BA !important;
}

/*display */

.display {
    display: flex;
}

/*fonts */

.text-time {
    font-weight: bold;
    font-size: 40px;
    color: #4F4F4F;
}

.text-status {
    font-weight: normal;
    font-size: 13px;
    color: #4F4F4F;
    padding-left: 20px;
}

.font-status {
    font-size: 10px;
    color: #169F66;
    margin-left: 9px;
    margin-top: 3px;
}

.font-change {
    color: #9C8715 !important;
    margin-left: 19px;
}

.content-user-box {
    width: 100%;
    height: 83px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 31px;
}

@media (max-width: 1100px) {
    .box-user {
        width: 26%;
    }
    .user-time {
        float: left;
        padding-left: 120px;
    }
    .user-img {
        display: flex;
        margin-left: -20px;
    }

    .user{
        font-size: 18px;
    }
}


@media (max-width: 800px) {
    .content-user-box {
        width: 50%;
        height: 380px;
    }
    .box-user {
        background-color: #fff;
        position: relative;
        text-align: center;
    }
    .user-img {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
    }
    .display {
        display: inline;
    }
    .user {
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 23px;
        font-size: 20px;
    }
    .box-status {
        text-align: center;
        margin-top: 45px !important;
        display: flex;
        position: relative;
    }
    .font-status {
        text-align: center;
        width: 45%;
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
    }
    .box-status .status {
        width: 55%;
        height: 43px;
        order: -1;
        margin-top: 70px;
        margin-right: auto;
        margin-left: auto;
    }
    .user-time {
        display: block;
        margin-top: 16px;
        margin-right: auto;
        margin-left: auto;
       
    }
    .text-time {
        font-size: 60px;
    }
    .text-status {
        padding-left: 0px;
    }
}

@media (max-width: 600px) {
    .box-status {
        margin-top: 22px !important;
    }

}
