.content-line-screen {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.content-line-screen>div {
    flex: 1;
}

.width {
    width: 30%;
}

/*box */

.box {
    width: 100%;
    height: 65px;
    border-radius: 10px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-blue {
    background: #00A7DC;
    color: #70EAEA;
}

.box-green {
    background: #1DC17C;
    color: #B0F5BF;
}

.box-purple {
    background: #A987E2;
    color: #D9C7F6;
}

.box-info {
    font-size: 12px;
    font-weight: 500;
}

.box-title {
    color: #4F4F4F;
    font-size: 9px;
    font-weight: bold;
    margin-top: 15px;
}

/*icons */

.people-icon {
    width: 20.23px;
    height: 14.22px;
    margin-top: 3.78px;
}

.check-icon {
    width: 18.33px;
    height: 18.33px;
    margin-top: 1.83px;
}

.time-icon {
    width: 25px;
    height: 25px;
}

/*padding */

.info-line {
    padding-right: 10px;
}

.text-info {
    padding-left: 20px;
    display: flex;
}

.info-time {
    padding-left: 23.44px;
}

.info-time-waiting{
    padding-left: 15.44px;
}

@media(max-width: 800px) {
    .content-line-screen>div {
        text-align: center;
        height: 408px;
        margin-top: 5px;
        margin-right: 16px;
    }
    .box-title {
        margin-top: 0px;
    }
    .text-info{
        margin-top: 12px;
    }
    .people-icon, .check-icon  {
        margin-top: 15.78px;
    }
    .info-time , .info-time-waiting{
        margin-top: 12.78px;
    }
    .time-icon{
        margin-top: 14px;
    }
   
}