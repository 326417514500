.appearance-background-picker {
    .image_picker {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;
        margin: -10px;

        .responsive {
            margin: 10px;
            padding: 3px;
            flex: 1 1 23%;
            border: 1px solid #d9d9d9;
            border-radius: 6px;
            transition: 250ms ease;

            &:before {
                content: "";
                position: absolute;
                display: block;
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                border-radius: 6px;
                background: #000;
            }

            &:hover {
                border: 1px solid #40a9ff;

                .thumbnail {
                    box-shadow: none;
                    opacity: 0.5;
                }
            }

            &.selected {
                opacity: 1;
                border: 1px solid #40a9ff;
                box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);

                .thumbnail {
                    opacity: 0.5;
                }
            }

            .thumbnail {
                position: relative;
                padding: 0;
                max-width: 100%;
                width: 100% !important;
                height: 100% !important;
                max-height: 250px;
                border-radius: 4px;
                border: none;
                transition: 250ms ease;
                z-index: 1;
            }

            .checked {
                bottom: 50%;
                right: 50%;
                transform: translate(50%, 50%);
                z-index: 2;

                .icon {
                    height: 30px;
                    width: 30px;
                    background-image: url("../../../assets/check-filled-blue.svg");
                }
            }
        }
    }
}

.chrome-picker {
    width: 100% !important;
    height: 220px;
    box-shadow: none !important;
    font-family: "Montserrat", sans-serif !important;

    > div:first-of-type {
        padding-bottom: 120px !important;
        border-radius: 8px !important;
    }

    > div:last-of-type {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.appearance-img {
    cursor: pointer;
    height: 165px !important;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    object-fit: contain;
}

.appearance-color-picker {
    margin-top: 5px;
    width: 100% !important;
    max-width: 310px;
    box-shadow: none !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 6px !important;
    overflow: hidden;
}

.appearance-picker-box {
    max-width: 240px;
    max-height: 322px;
    height: 100%;
    justify-content: space-between;
}

.appearance-picker-form {
    flex-direction: column;
}

.appearance-actions {
    justify-content: space-evenly;
}

.appearance-checkbox {
    font-size: 11px !important;
    margin-left: 5.25px !important;
    margin-right: 5.25px !important;
}

.settings-submit {
    // min-width: 115px !important;
    padding: 10px 20px;
}

.ant-upload {
    &.ant-upload-select-picture-card {
        background-color: transparent;
    }

    .logo-background {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 4px;

        img {
            height: 100%;
            object-fit: contain;
        }
    }
}

@media (max-width: 425px) {
    .appearance-background-picker .image_picker .responsive {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(50% - 10px);
        margin: 5px;
    }
}