/* text information*/

.instructions {
    font-size: 22px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.pdf-title  {
    text-align: center;
    margin-top: 10px;
}

.pdf-footer{
    text-align: center;
    margin-top: 30px !important;
}

.secure-text {
    margin-top: 50px;
}

/*QRCode*/

.align-img {
    text-align: center;
}

.qr-code-contact {
    width: 30%;
    margin-top: 60px;
}


/*Gathering pdf*/

.pdf-header{
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    margin-left: 50px;

}

.pdf-text{
    margin-top: 10px !important;
    font-size: 15px;
text-align: center;

}

.align-qrcode{

    text-align: center;
    margin-top: 30px !important;
}
