.input {
	margin-top: 5px;
	min-height: 2.25rem;
	border-radius: 5px;
	width: calc(100% - 10px);
	padding-left: 10px;
	border: 1px solid #e5e5e5;
	color: #000;
	font-family: "Montserrat";
	font-size: 13px;
}

.input-error-message {
	color: red;
	font-size: 11px;
	margin-top: 3px;
}

.error {
	border-color: red !important;
}

.hidden {
	display: none;
}
