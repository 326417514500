.container {
    display: flex;
    width: 100%;
  
}

.container>div {
    margin-top: 15px;
    padding: 15px;
}

/*QRCode box style*/

.QRCode-title {
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #4F4F4F;
    text-align: center;
    margin-bottom: 12px;
}

.QRCode-button {
    font-size: 10px;
    width: 100% !important;
    margin-top: 20px;
}

.display-flex {
    display: flex;
}

.qr-code{
    justify-content: center;
    display: flex;
}
