/*checkbox */

.slide{
    &.has-label {
        display: flex;
        align-items: center;

        .switch {
            margin-right: 10px;
        }
    }

    .switch {
        display: inline-block;
        height: 22px;
        position: relative;
        width: 37px;

        input {
            display: none;

            &:checked{
                &+.slider {
                    background-color: #0F5070;
                }

                &+.slider:before {
                    transform: translateX(15px);
                }
            }
        }

        .slider {
            background-color: #ccc;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: .4s;

            &:before {
                background-color: #fff;
                bottom: 1px;
                content: "";
                height: 20px;
                left: 1px;
                position: absolute;
                transition: all 250ms ease;
                width: 20px;
            }

            &.round {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }
        }
    }

    .switch-label {
        font-size: 12px;
        line-height: 1.2;
        color: #4F4F4F;
    }
}