.settings-form {
    padding-left: 24px !important;
}

.settings-row {
    flex-direction: row !important;
}

.settings-policy {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 12px;
    color: #797979;
}

.settings-field {
    max-width: 11.5%;
}

.settings-button {    
    align-self: flex-end;
    margin-bottom: .7rem;

    button {
        margin-top: .75rem;
        margin-left: 0 !important;
        min-height: 2.31rem;
    }
}

.setting-title {
    margin-top: 0;
    font-size: 12px;
    font-weight: 400;
}

.settings-input {   
    border-radius: 0;
    text-align: center;
}

.settings-input-addon {
    background: #FBFBFB;
    padding: 0 1.5rem;
    border: 1px solid #E5E5E5;
    margin-top: .3rem;
    display: flex;
    align-items: center;
}

.settings-input-prefix {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-right: 0px;
}

.settings-input-sufix {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-left: 0px;
}

.settings-input-button {
    cursor: pointer;
    font-size:25px;
}

.select__value-container {
    padding: 4px 8px !important;
}