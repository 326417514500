
/*icon*/

.company-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    height: auto;
}

/*footer "Not including you" */
.note {
    font-size: 10px;
    color: #808080;
    margin-top: 5px;
}

/*buttons */
.check-in-button {
    width: 90%;
    font-size: 20px;
}

.add {
    width: 40%;
    height: 54px;
    font-size: 25px;
color: #4F4F4F;
}

.subtract {
    width: 40%;
    height: 54px;
    font-size: 25px;
    color: #4F4F4F;
}

/*input "persons with you"*/
.input-number {
    display: flex;
    background: #FBFBFB;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
    width: 91%;
    height: 54px;
}

/*text with number of people*/
.number-of-people {
    width: 20%;
    height: 54px;
    text-align: center;
   margin-top: 15px;
}

.a{
    padding: 16px;
}