.modal-add-gathering {
    .modal-context {
        .form-group {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
        }

        input {
            border: 1px solid #e5e5e5 !important;
            height: 35px;
            border-radius: 6px;
            margin-top: 5px;
            padding-left: 10px !important;
        }

        label {
            text-transform: capitalize;
            font-size: 11px;
        }

        .modal-btn {
            min-width: 145px;
            padding: 10px 5px;
            color: #fff;
            border-radius: 6px;
            cursor: pointer;
        }

        .primary {
            background: #0f5172;
        }

        .secondary {
            background: #dbdbdb;
            color: #4f4f4f;
        }

       
    }
}
