textarea {
	border: 0.5px solid #e2e2e2;
	box-sizing: border-box;
	border-radius: 6px;
	padding-left: 10px !important;
	padding-top: 10px;
}

.textarea-container {
	display: flex;
	flex-direction: column;
}
