.attended-counter-form-row{
    display:flex;
    width:100%;
    flex-direction:row;
}


.attended-counter-form-group{
    display:flex;
    flex: 1;
    flex-direction:column;
    padding:10px 0;
}

.btn-attended-counter{
    height:35px;
    cursor: pointer;
    background: #DDDDDD;
    font-size:20px;
    border-radius:6px;
   
}

.people-attended-display{
    height:35px;
    display: flex;
    align-items:center;
    justify-content:center;
    font-weight: 500;
    font-size:15px;
    background: #fff;
  
}