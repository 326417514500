/* box style */
.alert-box {
	width: 350px;
	padding: 25px;
	background: #ffffff;
	box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
	border-radius: 11px;
}

/* class modal get the full background screen*/
.modal {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;

	.alert-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-top: 45px;

		svg {
			font-size: 30px;
		}
	}
}

/*icons*/
.alert-triangle-icon {
	margin-top: 35px;
	width: 20%;
}

.close-icon {
	float: right;
	cursor: pointer;
}

/*text of message */
.message {
	margin-top: 12px;
	line-height: 16px;
	text-align: center;
	color: #232526;
	font-size: 20px;

	span {
		line-height: 20px;
	}
}

/*button*/

.cancel-button {
	font-size: 17px;
	height: 50px;
	margin-top: 50px;
	cursor: pointer;
	background-color: transparent;
	box-shadow: none;
	color: #337ab7;
	margin-left: 240px;
	margin-top: 62px;
}

.modal-confirmation-actions {
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
	margin-bottom: 20px;
	.btn {
		padding: 10px 20px;
		border-radius: 6px;
		cursor: pointer;
	}
	.btn-cancel {
		background: #dbdbdb;
	}
	.btn-confirm {
		background: #0f5172;
		color: #fff;
	}
	.btn-save-notify {
		border: 1px solid #0f5172;
		background: #fff;
		color: #0f5172;
	}
}
