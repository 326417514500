.text-primary {
    color: #0D2E3F;
}

.text-link {
    color: #2d9cdb;
}

.text-error {
    color: #c30e0e;
}

.text-warning {
    color: #8d7d25;
}

.text-success {
    color: #336c36;
}

.text-info {
    color: #415588;
}

.text-black-1 {
    color: rgba(0, 0, 0, 0.02);;
}

.text-black-2 {
    color: rgba(0, 0, 0, 0.04);;
}

.text-black-3 {
    color: rgba(0, 0, 0, 0.06);;
}

.text-black-4 {
    color: rgba(0, 0, 0, 0.15);;
}

.text-black-5 {
    color: rgba(0, 0, 0, 0.25);;
}

.text-black-6,
.text-disable {
    color: rgba(0, 0, 0, 0.45);;
}

.text-black-7 {
    color: rgba(0, 0, 0, 0.65);;
}

.text-black-8 {
    color: rgba(0, 0, 0, 0.85);;
}

.text-white-1 {
    color: rgba(255, 255, 255, 0.04);;
}

.text-white-2 {
    color: rgba(255, 255, 255, 0.08);;
}

.text-white-3 {
    color: rgba(255, 255, 255, 0.12);;
}

.text-white-4 {
    color: rgba(255, 255, 255, 0.20);;
}

.text-white-5 {
    color: rgba(255, 255, 255, 0.30);;
}

.text-white-6 {
    color: rgba(255, 255, 255, 0.45);;
}

.text-white-7 {
    color: rgba(255, 255, 255, 0.65);;
}

.text-white-8 {
    color: rgba(255, 255, 255, 0.85);;
}