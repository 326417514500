.title-box {
	color: #4f4f4f;
	font-size: 9px;
	font-weight: bold;
	background: #e7e7e7;
	width: 25%;
	text-align: center;
	padding: 4px;
	margin-top: 12px;
	margin-bottom: 12px;
}

.client-box-container {
	width: 100%;
	height: 100%;
	background: #ffffff;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 15px;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);

	.people-box {
		border-radius: 10px;
		padding: 5px 10px;

		&.blue {
			background: #00a7dc;
			.font-blue {
				color: #70eaea;
				font-weight: 500;
			}
		}

		&.green {
			background: #1dc17c;

			.font-green {
				color: #b0f5bf;
				font-weight: 500;
			}
		}

		.people-number {
			font-size: 50px;
			color: #ffffff;
			font-weight: bold;
		}
	}
}
