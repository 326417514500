.react-datepicker-wrapper {
	input {
		font-size: 13px;
		color: #000;
		font-family: "Montserrat";
		min-height: 2.25rem;
		width: 100%;
		border-radius: 5px;
		padding-left: 5px;
	}
}

.calendar {
	position: absolute;
	margin-top: 30px;
	align-self: flex-end;
	color: #4f4f4f;
	margin-right: 5px;
}

.input-size {
	label {
		font-size: 12px;
	}
}

.datepicker {
	margin-top: 5px;
	height: 30px;
	border-radius: 5px;
	width: calc(100% - 10px);
	padding-left: 10px;
	border: 1px solid #e5e5e5;
}

.datepicker-container {
	display: flex;
	flex-direction: column;
}
