.list-ul {
    list-style: none;
    margin: 0;
    overflow-y: auto;
    height: 170px;
    white-space: nowrap;
    width: 100%;
}

.list-li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: transparent; /*Change the color */
    font-weight: 600; /* If you want it to be bold */
    font-size: 1.25rem; /* If you want it to be bold */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin: 0.25rem 0 0 0.5rem; /* Also needed for space (tweak if needed) */
    display: none;
}

.list-li.minor:before {
    color: #56ccf2; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
}

.list-li.major:before {
    color: #f2c94c; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
}

.list-li.critical:before {
    color: #eb5757; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
}

.list-li.safe:before {
    color: #5b5b5b; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
}

.list-li {
    border-radius: 1rem;
    padding: 0.5rem 1rem 0.5rem 0.1rem;
    display: flex;
    font-weight: 500;
    color: #bdbdbd;
    cursor: pointer;
    margin: 0;
}

.list-li:hover {
    background-color: #f5f5f557;
    color: #e2e2e2;
}

.list-li:focus {
    background-color: #c6bfbb;
    color: #333333;
}

.container-item {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    max-width: 80%;
    width: 100%;
}

.hidden {
    display: none;
}

.list-ul span {
    padding: 0 1rem 0 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title {
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
}

.subtitle {
    font-size: 0.7rem;
}

.expense-subtitle {
    padding-right: 0.5rem;
    font-size: 0.7rem;
}

.access-button {
    background: url(/src/assets/listButton.png) no-repeat;
    min-width: 1.5em;
    cursor: pointer;
    margin-top: 0.5rem;
}

.receipt-thumb {
    background-color: #eaeaea;
    display: flex;
    width: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.65rem;
    margin-left: 0.25rem;
    max-width: 3rem;
}

.list-link {
    color: inherit;
    text-decoration: inherit;
}
