.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;

    .username {
        padding: 5px 40px 5px 18px;
        margin-right: -30px;
        background: #f5f5f5;
        border-radius: 20px;
        color: #000;
    }

    .ant-avatar {
        border: 2px solid #f5f5f5;

        .anticon {
            color: #f5f5f5;
        }
    }
}

.ant-dropdown-menu.user-info-menu {
    padding: 0;
    border-radius: 4px;

    .ant-dropdown-menu-item-group-title {
        background-color: #0d2e3f;
        color: #f5f5f5;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .ant-dropdown-menu-item-group-list {
        margin: 0;
    }
}

@media (max-width: 425px) {
    .user-info {
        .username {
            max-width: 120px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
