.configuration-task-color {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    padding: 6px;
    background: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    color: #797979;
}

.configuration-color-set {
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.button-configuration{
    float: right;
    margin-top: 90px !important;
}