.line-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}

.item {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
}

.call-button {
    width: 100%;
    height: 65px;
    margin-top: 15px;
    background: #F3F3F3;
    border-radius: 10px;
    color: #4F4F4F;
    font-size: 18px;
    font-weight: normal;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

button:disabled {
    background: transparent;
    border: 1px solid #DCDCDC;
    cursor: auto;
    color: #A4A4A4;
}

.stop {
    background-color: #F8C8C8;
}

.fullscreen {
    font-size: 10px;
    align-items: center;
    color: #4F4F4F;
    padding: 14px;
    margin-bottom: 30px;
    margin-top: -20px;
}

.fullscreen-enabled {
    padding: 0px;
}

.fullscreen-img{
    vertical-align: text-top !important;
    margin-right: 4px;
}
.float-right {
    float: right;
    cursor: pointer;
}

:not(:root):fullscreen::backdrop {
    background: transparent !important;
}

.info-line-count {
    font-size: 65px;
    color: #FFF;
    font-weight: bold;
}

.user-item {
    display: contents;
}

@media (max-width: 1900px) {
    .status {
        width: auto;
    }
    .font-status {
        margin-right: 10px;
    }
    .user-time {
     margin-right: 10px;
    }
    .user{
        margin-top: 19px;
    }
    .user-time{
        float: left;
    }
}



@media (max-width: 1200px) {
    .line-item {
        width: 100%;
    }
    .actions-item {
        width: 100%;
    }
    .history-item {
        width: 100%;
    }
}


@media (max-width: 800px) {
    .line-container {
        justify-content: start;
    }
    .box-user {
        width: 90%;
    }
    .user-item {
        width: 50%;
        align-items: center;
    }
    .line-item {
        width: 50%;
        order: -1;
    }
    .actions-item {
        width: 100%;
    }
    .history-item {
        width: 100%;
    }
    .user-time {
        justify-content: center;
        margin-right: 75px;
        transform: translatex(-33%);
    }
    .no-show {
        display: none;
    }
    .font-status {
        margin-right: 30px;
    }
   
    .user-img {
        width: 40%;
    }
    .user {
        margin-top: 25px;
    }
}


@media (max-width: 750px) {
    .user-time {
       
        margin-right: 82px !important;
        transform: translatex(-14%) !important;
    }
}

@media (max-width: 600px) {
    .user-time {
        transform: translatex(-18%) !important;

    }
}