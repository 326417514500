.content-history-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.content-history-box>div {
    flex: 1;
}

/*table */

.table-history-box {
    width: 94%;
    font-size: 12px;
}

.table-history-box tr:nth-child(even) {
    background-color: #F1F1F1;
}

.table-history-box td:first-child {
    font-weight: bold;
}

.table-history-box td {
    background-color: #F1F1F1;
}

.td-content {
    align-items: flex-start;
}

.history-box-container {
    
    height: 240px;
    overflow-y: scroll;
}

.history-box-container::-webkit-scrollbar-track {
    background-color: #d3d3d3;
}