.form-list {
    .form-item {
        background: #e9e9e9;
        border-radius: 8px;
        padding: 8px;
        margin: 8px 0;
    }
}

.form-actions{
    margin-top: 20px;
    margin-left: 15px;
}

.popover-icon {
    margin-left: 6px;
    vertical-align: text-top;
}

@media (max-width: 991px) {
}

.origins {
    height: 100px;
    overflow: auto;
    padding-right: 8px;

    .origin {
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        padding: 4px 0;

        strong {
            font-size: 8px;
            font-weight: 700;
            text-transform: uppercase;
            &.ok {
                color: #4bd37b;
            }
            &.fail {
                color: #c30e0e;
            }
        }
    }
}

.ant-card {
    &.add {
        .ant-card-body {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 12px;
            font-weight: 700;
        }
    }
}

.c-pointer {
    cursor: pointer !important;
}
