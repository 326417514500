.form-container-gathering {
	width: 100%;
	flex: 4;

	form {
		background: #f4f4f4;
		//display: flex;
		padding: 10px;
		border-radius: 8px;
		//box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
	}

	.form-row {
		display: flex;
		width: 100%;
		flex-direction: row;
	}

	.form-group {
		display: flex;
		flex: 1;
		flex-direction: column;
		margin-right: 20px;
	}

	label {
		font-size: 13px;
		text-transform: capitalize;
		font-weight: 500;
	}

	input {
		background: #fbfbfb;
		margin-top: 5px;
		height: 30px;
		border-radius: 5px;
		width: 100%;
		padding-left: 5px;
	}

	button.submit {
		margin-top: 27px;
	}
}

.btn-action {
	background: #0f5071;
	padding: 10px 27px;
	color: #fff;
	border-radius: 6px;
	min-height: 35px;
	cursor: pointer;
}

.form-container {
	display: flex;
	margin-bottom: 40px;
	align-items: center;
}

.btn-add-gathering {
	padding: 10px 27px;
	border-radius: 6px;
	height: 35px;
	cursor: pointer;
	background-color: #fff;
	color: "#4f4f4f";
	margin-top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.add-gathering-box {
	flex: 1;
	justify-content: flex-end;
	display: flex;
	align-items: center;
}

@media (max-width: 1000px) {
	.add-gathering-box {
		flex: 2 1;
		justify-content: flex-end;
		display: flex;
		width: 100%;
	}

	.btn-add-gathering {
		padding: 10px 20px;
	}

	.form-container {
		flex-direction: column;
	}

	// .form-container-gathering {
	// 	flex: 3;
	// 	.form-row {
	// 		flex-direction: column;
	// 	}

	button.submit {
		width: 100%;
	}

	// 	.form-group {
	// 		width: 100%;
	// 	}

	// 	// form {
	// 	// 	width: auto;
	// 	// 	padding-bottom: 10px;
	// 	// 	padding-top: 0;
	// 	// }
	// }
}
