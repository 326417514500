.box-container {
    background-color: #2c2c2ca1;    
    border-radius: 1em;
    height: 100%;
    overflow: hidden;
}

.box-main {
    display: flex;
    flex: 1 1 auto;
}

.box-sizes {
    max-height: 170px;
    overflow-y: auto;
    margin: 1rem 1rem 1rem 1rem;    
}

.box-large {
    width: 50%;
}

.box-small {
    width: 100%;
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-expenses {
    display: block;
}

.react-calendar {
    background: #2c2c2c00;
    border: 0px;
    line-height: .5rem;    
    font-size: .75rem;
    color: #BDBDBD;
    max-height: 174px;
    border-radius: .75rem;
}

.task-box {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .75rem;
}

.list-li.todo:before, .list-li.progress:before, .list-li.delayed:before {
    content: "";
    display: inline-block;
    margin: 0 0 0 .5rem;; 
    height: 34px;
}

.list-li.todo:before {
    background: url("/src/assets/bulletTodo.png") no-repeat;
}

.list-li.progress:before {
    background: url("/src/assets/bulletDoing.png") no-repeat;
}

.list-li.delayed:before {
    background: url("/src/assets/bulletDelayed.png") no-repeat;
}

.react-calendar:hover {
    background: #f5f5f513;
}

.react-calendar button {
    border-radius: .75rem;
    color: #BDBDBD;
}

.react-calendar__tile {
    padding: 0.25em 0.25em;
}

.react-calendar__tile--active {
    background: #C6BFBB !important;
    color: #333333 !important;  
}

.react-calendar__navigation button:enabled, .react-calendar__navigation {
    background-color: transparent !important;
    cursor: auto;
    max-height: 35px
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: #f5f5f557;
    color: #e2e2e2;
}

.react-calendar__navigation__arrow, .react-calendar__navigation__prev2-button{
    display: none;
}

.react-calendar__navigation__label__labelText, .react-calendar__navigation__label__labelText--from {
    color: #BDBDBD;  
    font-size: .75rem;
}

.react-calendar__tile--now {
    background: #C6BFBB;
    color: #333333 !important;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #C6BFBB;
}

.box-footer {
    margin: auto;    
    padding: 1.3em;
    text-align: center;
    font-size: .8rem;
    font-weight: 400;
    color: #ffffff;
    cursor:pointer;
    border-radius: .75rem;
}

.box-footer:hover {
    background-color: #f5f5f557;
    color: #e2e2e2;
}

.box-footer:focus {   
    background-color: #C6BFBB;
    color: #333333;  
}

.box-logo {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 12.5px;
    border-radius: 6px;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.logo {
    background-image: url(/src/assets/logoBackground.png);
    filter: blur(8px);
    height: 100%;
    background-size: cover;
}

.logo-img {
    size: 1em;
    max-width: 100%;
    flex: 1 1 auto;
    max-height: 170px;
    object-fit: contain;
}

.module {
    cursor: pointer;
}
