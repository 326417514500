.select__control {
	min-height: 30px !important;
	margin-top: 5px;
	border: 0.5px solid #e2e2e2 !important;
}

.select__multi-value {
	align-self: center !important;
}

.select__control:focus {
	border-width: 0 !important;
}

.select__indicator-separator {
	background: #fff !important;
}

.select__multi-value__remove {
	cursor: pointer;
}

.select__multi-value__remove:hover {
	background-color: #7e7b7b !important;
	color: white !important;
}

/* .select__placeholder{
    position: relative !important;
    align-self: center !important;
}  */
.select__value-container {
	font-family: "Montserrat";
	font-size: 13px;
	color: #000;
}

.select__value-container,
.select__indicator-separator,
.select__indicator {
	background: #fff !important;
}

.select__control:focus-within {
	border: 0.5px solid #e2e2e2 !important;
	box-sizing: border-box;
	border-radius: 6px;
	box-shadow: none;
}

.css-1okebmr-indicatorSeparator {
	margin: 0 !important;
}

.select__indicator,
.select__indicators {
	border-radius: 4px;
	cursor: pointer;
}

.select__input {
	display: none !important;
}
