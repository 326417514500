.map {
    position: relative !important;
    border-radius: 8px;
    overflow: hidden;

    .marker {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        padding: 6px 8px;
        border-radius: 2px;
        background: #fafafa;
        font-size: 11px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.85);
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
        transition: transform 250ms ease;

        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: -5px;
            left: calc(50% - 5px);
            width: 10px;
            height: 10px;
            background: #fafafa;
            transform: rotate(45deg);
        }

        &:hover {
            transform: scale(1.1);
            z-index: 9999;
        }
    }

    .empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        background: #FAFAFA;
        color: rgba(0, 0, 0, 0.45);

        svg {
            font-size: 24px;
            color: #8C8C8C;
        }
    }
}
