/*contact communication container */

.contact-container {
	// width: 100%;
	// max-width: 100%;
	// display: flex;
	// flex-wrap: wrap;
	// margin: 0 auto;
	// flex-direction: column;

	.box-filter-form {
		background: #f4f4f4;
		border-radius: 8px;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
		margin-bottom: 20px;
		padding: 10px;
	}
	.message-box-container {
		background: #f4f4f4;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		height: 100%;
		padding: 10px;
	}
}

.message-info {
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
}

.table-margin {
	margin-top: 10px;
}

/*button export data  */

.export-data-button {
	width: 200px;
	height: 35px;
	background: #ffffff;
	border-radius: 6px;
	margin-top: 15px;
	float: right;
	cursor: pointer;
	font-size: 10px;
	color: #4f4f4f;
	text-align: center;
	margin-left: 15px;
	flex: 1 1 200px;
	flex-wrap: wrap;
}

.export-data-icon {
	margin-left: 10px;
	margin-right: 15.5px;
}

/*customer inside box */

.slides-align {
	margin-top: 20px;
}

.button-send {
	margin-top: 16px;
}

.box-content {
	background: #f4f4f4;
	border-radius: 8px;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
}

// .time-label {
// 	align-self: baseline;
// 	margin-top: 10px;
// }

// .input-time {
// 	width: 75%;
// 	display: flex;
// 	justify-content: space-between;
// }

// .input-size {
// 	width: 79%;
// 	display: flex;

// 	label {
// 		font-size: 10px !important;
// 	}

// 	.calendar {
// 		margin-top: 25px !important;
// 	}
// 	input {
// 		border-radius: 6px !important;
// 		width: 97% !important;
// 		border: 0.5px solid #e2e2e2;
// 	}

// 	.form-group:first-child {
// 		margin-right: 10px;
// 	}

// 	.form-group:last-child {
// 		margin-right: -10px;
// 	}
// }

// .input-time {
// 	margin-top: -6px;
// }

// @media (max-width: 1700px) {
// 	.button-send {
// 		width: 40%;
// 	}

// 	.export-data-button {
// 		width: 24%;
// 	}
// }

// @media (max-width: 1050px) {
// 	.contact-container {
// 		width: 100%;
// 	}
// 	.box-content {
// 		background: transparent;
// 	}
// 	.customer-box {
// 		width: 100%;
// 		margin-top: 20px;
// 		border-radius: 8px;
// 	}
// 	.message-content {
// 		width: 100%;
// 	}
// 	.button-send {
// 		width: 80%;
// 	}
// 	.export-data-button {
// 		width: 48%;
// 	}
// 	.info-line-count {
// 		font-size: 45px;
// 	}
// 	.align-info {
// 		margin-top: 10px;
// 	}
// 	.input-time {
// 		width: 90%;
// 	}
// 	.calendar {
// 		margin-top: 22px;
// 	}

// 	.input-size {
// 		width: 98%;
// 	}
// }

// @media (max-width: 1050px) {
// 	.input-size {
// 		width: 97%;
// 	}

// 	.table-margin {
// 		overflow-x: scroll;
// 	}
// 	.table-margin ::-webkit-scrollbar {
// 		height: 5px;
// 	}
// }
