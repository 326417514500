.ant-btn {
    min-width: 100px;
    height: 35px;
    padding: 7px 15px;
    border-radius: 6px;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    color: #0d2e3f;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: none;

    &:before {
        display: none !important;
    }

    &:hover {
        color: #617782;
        background-color: #ffffff;
        border-color: #ffffff;
    }

    &:focus,
    &:active {
        font-weight: 500;
        color: #0d2e3f;
        background-color: #fff;
        border-color: #fff;
    }

    &.ant-btn-loading {
        color: #262626;
        border-color: rgba(255, 255, 255, 0.65);
        background-color: rgba(255, 255, 255, 0.65);
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        color: #bfbfbf;
        background-color: #f5f5f5;
        border-color: #ececec;
    }

    &.ant-btn-dashed {
        border-color: #d9d9d9;

        &:hover {
            border-width: 0.8px;
            border-color: #617782;
        }

        &:focus,
        &:active {
            border-width: 1px;
            border-color: #617782;
        }

        &.ant-btn-loading {
            color: #4a626f;
            border-color: #617782;
            background-color: #f5f5f5;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            color: #bfbfbf;
            background-color: #f5f5f5;
            border-color: #617782;
        }
    }

    &.ant-btn-secondary {
        color: #0d2e3f;
        background-color: transparent;
        border-color: #0d2e3f;

        &:hover {
            color: #617782;
            background-color: transparent;
            border-color: #617782;
        }

        &:focus,
        &:active {
            font-weight: 500;
            color: #0d2e3f;
            background-color: transparent;
            border-color: #0d2e3f;
        }

        &.ant-btn-loading {
            color: #4a626f;
            background-color: transparent;
            border-color: #4a626f;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            color: rgba(0, 0, 0, 0.45);
            background-color: transparent;
            border-color: #D9D9D9;
        }
    }

    &.ant-btn-primary {
        color: #fff;
        background-color: #0d2e3f;
        border-color: #0d2e3f;

        &:hover {
            color: #fff;
            background-color: #617782;
            border-color: #617782;
        }

        &:focus,
        &:active {
            font-weight: 500;
            color: #fff;
            background-color: #0d2e3f;
            border-color: #071b25;
        }

        &.ant-btn-loading {
            color: #fff;
            background-color: #4a626f;
            border-color: #4a626f;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            color: rgba(0, 0, 0, 0.45);
            background-color: #f5f5f5;
            border-color: #ececec;
        }
    }

    &.ant-btn-text {
        color: #262626 !important;
        background-color: transparent !important;
        border-color: transparent !important;

        &:focus,
        &:active {
            font-weight: 500;
        }

        &.ant-btn-loading {
            color: rgba(38, 38, 38, 0.65) !important;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            color: #bfbfbf !important;
        }
    }

    &.ant-btn-link {
        min-width: unset;
        height: auto;
        padding: unset;
        color: #2d9cdb !important;
        background-color: transparent !important;
        border-color: transparent !important;

        &:focus,
        &:active {
            font-weight: 500;
            color: #096dd9 !important;
        }

        &.ant-btn-loading {
            color: #1890ff !important;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
            color: #bfbfbf !important;
        }
    }

    &.ant-btn-lg {
        height: 40px;
        padding: 10px 14px;
    }

    &.ant-btn-sm {
        height: 30px;
        padding: 4px 14px;
    }

    &.ant-btn-circle {
        border-radius: 50%;
    }

    &.ant-btn-icon-only {
        width: 35px;
        height: 35px;
        min-width: unset;
        padding: 6px;

        &.ant-btn-lg {
            width: 40px;
            height: 40px;
            padding: 8px;
        }

        &.ant-btn-sm {
            width: 30px;
            height: 30px;
            padding: 4px;
        }
    }
}
