@import "~antd/dist/antd.css";
@import "../../components/globalStyles/button.scss";
@import "../../components/globalStyles/table.scss";

.ant-layout {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(25px);

    &.ant-layout-has-sider {
        background: none;
        backdrop-filter: none;
    }

    .ant-layout-sider {
        overflow-x: hidden;
        background: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(25px);
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

        .ant-layout-sider-children {
            padding-bottom: 115px;
            max-height: calc(100% - 115px);
        }

        .sider-header {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 12.5px;
            padding: 12.5px;
            border-radius: 6px;
        }

        .sider-launcher {
            min-height: 50px;
            padding: 15px 25px;
            font-size: 13px;
            line-height: 22px;
            transition: background-color 250ms ease;

            &:hover {
                background-color: rgba(31, 31, 31, 0.85);
            }

            a {
                color: #fff;

                .icon-launcher {
                    margin-right: 14px;
                }
            }
        }

        .sider-module {
            min-height: 50px;
            padding: 16px 25px;
            font-size: 9px;
            line-height: 18px;
            font-weight: 500;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.45);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .sider-copywrite {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            padding: 25px 25px 40px;
            font-size: 7px;
            line-height: 16px;
            text-transform: uppercase;
            text-align: center;
            color: rgba(255, 255, 255, 0.45);

            img {
                margin-top: 20px;
            }
        }
    }

    .ant-layout-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        padding: 10px 15px;
        line-height: 65px;
        background: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(25px);
    }

    .ant-layout-content {
        padding: 32px 40px 32px 32px;
        overflow-y: auto;
    }

    .ant-btn.menu-toggle {
        position: absolute;
        left: 15px;
        color: #fff;
        background: transparent;
        border: none;
        padding: 0;
        height: 32px;
        min-width: auto;
    }

    .layout-logo {
        height: 36px;
        max-width: 100%;
        object-fit: contain;
        transition: opacity 250ms ease;
    }
}

.ant-menu {
    font-size: 11px;
    line-height: 20px;
    max-height: calc(100vh - 295px);
    overflow: auto;

    &.ant-menu-dark {
        background: transparent;

        .ant-menu-submenu-title,
        .ant-menu-item {
            min-height: 50px;
            padding: 15px 25px !important;
            margin: 0;
            font-size: 13px;
            line-height: 22px;
            color: #fff;
            font-weight: 400;
            transition: background-color 250ms ease;

            &:hover {
                background: rgba(31, 31, 31, 0.85);
            }

            &.ant-menu-item-selected {
                background: transparent;
                border-left: 5px solid #0f5172;
                padding-left: 20px !important;

                &:hover {
                    background: rgba(31, 31, 31, 0.85);
                }
            }

            &.is-new {
                display: flex;
                justify-content: space-between;

                .ant-tag.ant-tag-success {
                    color: #04131a;
                    background-color: #65c439;
                }
            }
        }

        .ant-menu-sub {
            background: rgba(51, 51, 51, 0.85);
            .ant-menu-item {
                &:hover {
                    background: rgba(31, 31, 31, 0.85);
                }

                &.ant-menu-item-selected {
                    border-width: 0;
                    padding-left: 25px !important;
                }

                &.is-new {
                    display: flex;
                    justify-content: space-between;

                    .ant-tag.ant-tag-success {
                        color: #04131a;
                        background-color: #65c439;
                    }
                }
            }
        }
    }
}

.ant-card {
    color: #4f4f4f;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 13px;
    line-height: 22px;

    &.medium {
        .ant-card-body {
            padding: 15px 20px;
        }
    }

    &.small {
        .ant-card-body {
            padding: 12px 10px;
        }
    }

    &.no-padding {
        .ant-card-body {
            padding: 0;
        }
    }

    &.ant-card-bordered {
        box-shadow: none;
        border-color: #e9e9e9;
    }

    .ant-card-head {
        font-size: 13px;
        padding: 10px 20px;
        border: none;
        background: #f2f2f2;
        border-radius: 8px 8px 0 0;
        min-height: unset;

        .ant-card-head-title {
            padding: 0;
        }
    }

    .ant-card-body {
        padding: 25px 20px;
    }

    .ant-card-actions {
        background: none;
    }
}

.ant-space {
    &.ant-space-vertical {
        > .ant-space-item:not(:last-child) {
            margin-bottom: 10px !important;
        }
    }
    &.ant-space-horizontal {
        > .ant-space-item:not(:last-child) {
            margin-right: 10px !important;
        }
    }

    &.ant-space-middle {
        &.ant-space-vertical {
            > .ant-space-item:not(:last-child) {
                margin-bottom: 20px !important;
            }
        }
        &.ant-space-horizontal {
            > .ant-space-item:not(:last-child) {
                margin-right: 20px !important;
            }
        }
    }

    &.ant-space-large {
        &.ant-space-vertical {
            > .ant-space-item:not(:last-child) {
                margin-bottom: 30px !important;
            }
        }
        &.ant-space-horizontal {
            > .ant-space-item:not(:last-child) {
                margin-right: 30px !important;
            }
        }
    }
}

.ant-tag {
    font-size: 7px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    border-radius: 25px;
    border: none;
    padding: 2px 12px;
    margin: 0;
    background: #d9d9d9;
    color: rgba(0, 0, 0, 0.45);

    &:hover {
        opacity: 1;
    }

    &.medium {
        font-size: 9px;
        line-height: 18px;
    }

    &.ant-tag-info {
        color: #415588;
        background-color: #b7eab9;
    }

    &.ant-tag-success {
        color: #336c36;
        background-color: #b7eab9;
    }

    &.ant-tag-warning {
        color: #8d7d25;
        background-color: #fff4ba;
    }

    &.ant-tag-error {
        color: #c30e0e;
        background-color: #f8c8c8;
    }
}

.ant-drawer {
    &.ant-drawer-right.ant-drawer-open {
        width: 95% !important;
        transition: none !important;

        .ant-drawer-content-wrapper {
            box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
            transform: translateX(0%) !important;
        }
    }

    .ant-drawer-content-wrapper {
        background: rgba(240, 240, 240, 0.65);
        backdrop-filter: blur(20px);
        transform: translateX(100%) !important;
    }

    .ant-drawer-content {
        background-color: transparent;
    }

    .ant-drawer-header {
        display: flex;
        align-items: center;
        background: transparent;
        padding: 30px;
        border: none;
        color: #4f4f4f;

        .ant-drawer-title {
            font-size: 12px;
            line-height: 1.5;
            font-weight: 400;
            color: #4f4f4f;
            text-transform: uppercase;
        }

        .ant-drawer-close {
            position: static;
            display: flex;
            align-items: center;
            justify-content: center;
            order: -1;
            width: 26px;
            height: 26px;
            background: #fff;
            border-radius: 50%;
            padding: 0;
            margin-right: 24px;
            color: #4f4f4f;

            .anticon.anticon-close {
                padding: 0;
                svg {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .ant-drawer-body {
        padding-top: 5px;
    }
}

.ant-modal-root {
    .ant-modal-mask {
        background-color: rgba(240, 240, 240, 0.65);
        backdrop-filter: blur(20px);
    }

    .ant-modal {
        .ant-modal-content {
            border-radius: 8px;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);

            .ant-modal-close {
                top: 16px;
                right: 24px;
                color: #bfbfbf;

                .ant-modal-close-x {
                    width: 24px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .ant-modal-header {
                padding: 18px 24px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                .ant-modal-title {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 11px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }

            .ant-modal-body {
                font-size: 13px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.65);
            }

            .ant-modal-footer {
                text-align: left;
            }
        }
    }
}

.ant-form {
    &.ant-form-vertical .ant-form-item-label {
        padding: 0 0 10px 0;
    }

    .ant-form-item {
        margin-bottom: 20px;

        &.show-error {
            .ant-form-item-explain {
                display: block;
                min-height: unset;
            }
        }
    }

    label {
        font-size: 13px;
        line-height: 22px;
        color: #4f4f4f;
        font-weight: 500;
    }

    .label-switch {
        font-size: 11px;
        line-height: 20px;
    }

    .ant-form-item-control-input {
        min-height: auto;
    }

    .ant-form-item-explain {
        display: none;
        padding: 10px 0 0 0;
        font-size: 9px;
        line-height: 18px;
    }

    .ant-form-item-extra {
        padding: 10px 0 0 0;
        font-size: 9px;
        line-height: 18px;
    }
}

::placeholder {
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
}

:-moz-placeholder {
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
}

:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
}

.ant-input,
.ant-input-number {
    width: 100%;
    height: 35px;
    border-color: #d9d9d9;
    border-radius: 6px;
    font-size: 13px;
    line-height: 22px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
        border-color: #40a9ff;
    }

    &:focus,
    &.ant-input-focused {
        border-color: #40a9ff;
        box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
    }

    &[disabled],
    &.ant-input-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
    }

    &.ant-input-lg,
    &.ant-input-number-lg {
        padding: 8px 12px;
    }

    &.ant-input-sm,
    &.ant-input-number-sm {
        padding: 4px 8px;
    }

    .ant-input-number-input {
        height: auto;
        padding: 0 16px 0 0;
    }

    .ant-input-number-handler-wrap {
        border-radius: 0 6px 6px 0;
    }
}

textarea.ant-input {
    width: 100%;

    &.ant-input-lg {
        padding: 9px 16px;
    }

    &.ant-input-sm {
        padding: 2px 8px;
    }
}

.ant-input-textarea-show-count::after {
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.25);
}

.ant-input-affix-wrapper {
    border-color: #d9d9d9;
    border-radius: 6px;
    font-size: 13px;
    line-height: 22px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
        border-color: #40a9ff;
    }

    &:focus,
    &.ant-input-focused {
        color: rgba(0, 0, 0, 0.25);
        border-color: #40a9ff;
        box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
    }

    &.ant-input-affix-wrapper-disabled {
        background-color: #f5f5f5;
        border-color: #d9d9d9;

        .ant-input-prefix,
        .ant-input-suffix {
            color: #bfbfbf;
        }
    }

    &.ant-input-affix-wrapper-lg {
        padding: 8px 12px;
    }

    &.ant-input-affix-wrapper-sm {
        padding: 4px 8px;
    }

    .ant-input-prefix,
    .ant-input-suffix {
        font-size: 14px;
        line-height: 14px;
    }

    .ant-input-prefix {
        margin-right: 6px;
        color: #262626;
    }

    .ant-input-suffix {
        margin-left: 6px;
        color: #8c8c8c;
    }

    > .ant-input {
        height: auto;
    }
}

.ant-input-group-wrapper {
    .ant-input-group {
        .ant-input-group-addon {
            padding: 6px 12px;
            background-color: #f5f5f5;
            font-size: 11px;
            line-height: 20px;
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.65);

            .anticon {
                font-size: 14px;
            }
        }

        > .ant-input:first-child,
        .ant-input-group-addon:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        > .ant-input:last-child,
        .ant-input-group-addon:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    &.ant-input-group-wrapper-sm {
        .ant-input-group-addon {
            padding: 4px 12px;
        }
    }

    &:hover {
        .ant-input-group-addon,
        .ant-input {
            border-color: #40a9ff;
        }
    }

    .ant-input-disabled {
        background-color: #fafafa;

        ~ .ant-input-group-addon {
            color: rgba(0, 0, 0, 0.45);
        }
    }
}

.ant-select {
    font-size: 11px;
    line-height: 20px;
    color: #4f4f4f;

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
            border-color: #40a9ff;
            box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
        }
    }

    &:not(.ant-select-disabled):hover {
        .ant-select-selector {
            border-color: #40a9ff;
        }
    }

    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            border-color: #d9d9d9;
            border-radius: 6px;
            padding: 6px 12px;
            height: 35px;
        }
    }

    .ant-select-arrow {
        right: 14px;
        top: 50%;
        margin: 0;
        color: #4f4f4f;
        transform: translateY(-50%);
    }

    .ant-select-selector {
        border-color: #d9d9d9;
        border-radius: 6px;
        padding: 6px 12px;
        height: 35px;

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            line-height: inherit;
        }
    }

    &.ant-select-multiple {
        .ant-select-selector {
            padding: 4px 6px;
            height: auto;
            min-height: 35px;
        }

        .ant-select-selection-overflow-item {
            margin: 2px;

            .ant-select-selection-item {
                height: auto;
                background: #ededed;
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                padding: 2px;
                margin: 0;
            }
        }
    }

    &.ant-select-lg {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                padding: 8px 12px;
                height: 40px;

                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    line-height: 22px;
                }
            }
        }

        .ant-select-selector {
            padding: 8px 12px;
            height: 40px;

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                line-height: 22px;
            }
        }

        &.ant-select-multiple {
            .ant-select-selector {
                padding: 4px 6px;
                min-height: 40px;
            }
        }
    }

    &.ant-select-sm {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                padding: 4px 8px;
                height: 32px;

                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    line-height: 22px;
                }
            }
        }

        .ant-select-selector {
            padding: 4px 8px;
            height: 32px;

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                line-height: 22px;
            }
        }

        &.ant-select-multiple {
            .ant-select-selector {
                padding: 2px 6px;
                min-height: 32px;
            }
        }
    }
}

.ant-select-item {
    font-size: 11px;
    line-height: 22px;
}

.ant-switch {
    background-color: #bfbfbf;
    font-size: 11px;
    line-height: 20px;

    &.ant-switch-checked {
        background-color: #0d2e3f;
        border: none;

        &:focus {
            box-shadow: 0 0 0 2px rgba(15, 81, 114, 0.2);
        }

        &.ant-switch-loading.ant-switch-disabled {
            background-color: #4a626f;

            .ant-switch-loading-icon {
                color: #0d2e3f;
            }
        }

        .ant-switch-loading-icon {
            color: #0d2e3f;
        }
    }

    &.ant-switch-disabled {
        background-color: #d9d9d9;
        color: #fff;
        border: 0;
        opacity: 1;

        .ant-switch-loading-icon {
            color: #bfbfbf;
        }
    }

    &.ant-switch-small {
        font-size: 9px;
        line-height: 18px;

        .ant-switch-inner {
            font-size: 9px;
            line-height: 18px;
        }
    }
}

.ant-tabs {
    overflow: visible;

    &.ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: 1px solid #d1d1d1;
    }

    .ant-tabs-nav {
        margin-bottom: 0;

        &:before {
            content: none;
        }

        .ant-tabs-nav-wrap {
            padding: 0;

            .ant-tabs-nav-list {

                .ant-tabs-tab {
                    min-width: 140px;
                    padding: 9px 30px;
                    justify-content: center;
                    background: #f5f5f5;
                    margin: 0;
                    border: 1px solid #d9d9d9;
                    border-bottom: none;
                    border-radius: 10px 10px 0px 0px;

                    &.ant-tabs-tab-active {
                        background: #fff;
                    }
                    
                    &:not(.ant-tabs-tab-active):after {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: -1px;
                        width: calc(100% + 1px);
                        height: 1px;
                        background-color: #d9d9d9;
                    }

                    + .ant-tabs-tab {
                        margin: 0;
                    }
                }
            }
        }

        .ant-tabs-ink-bar {
            display: none;
        }
    }

    .ant-tabs-tab {
        padding: 0 0 4px;
        margin: 0 25px;

        &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #0d2e3f;
            font-weight: 700;
            text-shadow: none;
        }

        .ant-tabs-tab-btn {
            font-size: 13px;
            line-height: 22px;
            color: #4f4f4f;
        }
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 25px;
    }

    .ant-tabs-content {
        padding-left: 0.5px;

        .ant-tabs-tabpane {
            background: #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10);
            border-radius: 0 8px 8px 8px;
            padding: 25px 20px;
        }
    }

    &.ant-tabs-flat {

        .ant-tabs-nav {
            z-index: 2;
            bottom: -1px;
        }
        
        .ant-tabs-content-holder {
            position: relative;
            z-index: 1;

            .ant-tabs-content {
                padding-left: 0;
        
                .ant-tabs-tabpane {
                    box-shadow: none;
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }
}

.ant-message-notice-content {
    padding: 0;
    background: transparent;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;

    .ant-message-custom-content {
        padding: 9px 11px;

        &.ant-message-error {
            background-color: #f8c8c8;
            color: #c30e0e;

            .anticon {
                color: #c30e0e;
            }
        }

        &.ant-message-warning {
            background-color: #fff4ba;
            color: #8d7d25;

            .anticon {
                color: #8d7d25;
            }
        }

        &.ant-message-success {
            background-color: #b7eab9;
            color: #336c36;

            .anticon {
                color: #336c36;
            }
        }

        &.ant-message-info {
            background-color: #75c0e6;
            color: #415588;

            .anticon {
                color: #415588;
            }
        }
    }
}

.ant-alert {
    font-size: 11px;
    line-height: 20px;
    padding: 7px 15px;
    border-radius: 0;

    &.ant-alert-error {
        background-color: #f8c8c8;
        color: #c30e0e;

        .anticon,
        .ant-alert-message {
            color: #c30e0e;
        }
    }

    &.ant-alert-warning {
        background-color: #fff4ba;
        color: #8d7d25;

        .anticon,
        .ant-alert-message {
            color: #8d7d25;
        }
    }

    &.ant-alert-success {
        background-color: #b7eab9;
        color: #336c36;

        .anticon,
        .ant-alert-message {
            color: #336c36;
        }
    }

    &.ant-alert-info {
        background-color: #75c0e6;
        color: #415588;

        .anticon,
        .ant-alert-message {
            color: #415588;
        }
    }
}

.ant-progress.main {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 0;
    z-index: 99999999999;

    .ant-progress-inner {
        border-radius: 0;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(25px);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        .ant-progress-success-bg,
        .ant-progress-bg {
            border-radius: 0 !important;
            background-color: #0d2e3f;
        }
    }
}

.ant-upload-picture-card-wrapper {
    .ant-upload-select {
        padding: 4px;
    }

    &.upload-single {
        .ant-upload-select {
            margin: 0;

            .ant-upload {
                position: relative;
            }
        }

        .ant-upload-select-picture-card {
            width: 100%;
            height: 165px;
            background: #ffffff;
            border: 1px solid #dddddd;
            box-sizing: border-box;
            border-radius: 4px;

            .anticon-upload,
            .anticon-cloud-upload {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 4px;
                color: #40a9ff;
                font-size: 24px;
                opacity: 0;
                transition: 250ms ease;
            }

            &:hover {
                border-color: #40a9ff;

                .anticon-upload,
                .anticon-cloud-upload {
                    opacity: 1;
                }
            }
        }
    }

    .ant-upload-list {
        font-size: 11px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.45);

        .ant-upload-list-picture-card-container {
            width: 140px;
            height: 100px;
            margin: 0 20px 10px 0;
        }

        .ant-upload-list-item {
            padding: 2px;
            border-radius: 6px;

            .ant-upload-list-item-info {
                border-radius: 4px;
            }

            .ant-upload-list-item-thumbnail,
            .ant-upload-list-item-thumbnail img {
                object-fit: cover;
            }
        }

        .ant-upload-select-picture-card {
            width: 140px;
            height: 100px;
            padding: 2px;
            margin: 0 0 10px 0;
            border-radius: 6px;

            .ant-upload {
                font-size: 11px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.45);

                .anticon {
                    font-size: 18px;
                }
            }
        }
    }
}

.ant-upload-select-picture {
    .ant-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
        cursor: pointer;

        &.ant-upload-disabled {
            cursor: not-allowed;
        }

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }

        .anticon {
            font-size: 17px;
            color: #d9d9d9;
        }

        .anticon-upload,
        .anticon-cloud-upload {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            opacity: 0;
            transition: 250ms ease;
        }

        &:hover {
            .anticon-upload,
            .anticon-cloud-upload {
                opacity: 1;
            }
        }
    }
}

.ant-spin-dot-item {
    background-color: #0d2e3f;
}

.ant-collapse {
    font-size: 13px;
    line-height: 22px;
    border-color: rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-color: #fff;

    > .ant-collapse-item {
        border-color: rgba(0, 0, 0, 0.06);

        &:last-child {
            border-radius: 0 0 6px 6px;

            > .ant-collapse-header,
            > .ant-collapse-content {
                border-radius: 0 0 6px 6px;
            }
        }

        > .ant-collapse-header {
            padding: 10px;
            font-weight: 500;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        > .ant-collapse-content {
            border-color: rgba(0, 0, 0, 0.06);
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
            background: none;
        }
    }
}

.ant-comment {
    &:last-of-type {
        .ant-comment-inner {
            padding-bottom: 0;
        }
    }

    .ant-comment-inner {
        padding-top: 0;
        padding-bottom: 30px;
    }

    .ant-comment-content-author {
        font-size: 13px;
        font-weight: 500;

        .ant-comment-content-author-name {
            font-size: 13px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
        }

        .ant-comment-content-author-time {
            font-size: 13px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
}

.ant-breadcrumb {
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;

    .ant-breadcrumb-link {
        a {
            color: rgba(0, 0, 0, 0.45);
        }
    }

    > span:not(:last-child) {
        .ant-breadcrumb-link {
            cursor: pointer;

            &:hover {
                color: #1890ff;
            }
        }
    }
}

@media (max-width: 991px) {
    .ant-layout {
        .ant-layout-sider {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            max-width: 85% !important;
            min-width: auto !important;
            overflow-x: hidden;
            z-index: 1040;

            .sider-header {
                margin: 0 0 15px;
                padding: 14px 50px;
                border-radius: 0;
            }
        }

        .ant-layout-content {
            padding: 24px;
        }
    }

    .ant-drawer {
        &.ant-drawer-right.ant-drawer-open {
            width: 100% !important;

            .ant-drawer-content-wrapper {
                box-shadow: none;
            }
        }

        .ant-drawer-header {
            padding: 19px 25px;
        }
    }
}
