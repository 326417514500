.ant-table {
    border-radius: 6px;
    background: #ffffff;
    font-size: 13px;
    line-height: 22px;

    table {
        margin-top: 0;
        border-radius: 6px;
        background: transparent;
        overflow: hidden;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    }

    .ant-table-thead {
        font-size: 11px;
        line-height: 20px;

        .ant-table-cell {
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 20px 16px;
            font-weight: 700;

            &.ant-table-column-has-sorters {
                &:hover {
                    background: transparent;
                }
            }

            &.ant-table-column-sort {
                background: #f0f0f0;

                &:hover {
                    background: #f0f0f0;
                }
            }
        }
    }

    .ant-table-tbody {
        background: transparent;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        font-size: 13px;
        line-height: 22px;

        .ant-table-row {
            &:last-of-type {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            &:hover {
                .ant-table-cell {
                    background-color: #f0f0f0;
                }
            }

            .ant-table-cell {
                height: 55px;
                padding: 10px 16px;
                border: none;
                background: #f4f4f4;

                &.ant-table-column-sort {
                    background: #f0f0f0;
                }
            }
        }

        .ant-table-placeholder {
            .ant-table-cell {
                background: #f4f4f4;
            }

            &:hover {
                .ant-table-cell {
                    background: #f0f0f0;
                }
            }
        }
    }

    &.ant-table-middle {
        font-size: 13px;
        line-height: 22px;

        .ant-table-thead {
            line-height: 19px;

            .ant-table-cell {
                padding: 18px 16px;
            }
        }

        .ant-table-tbody {
            .ant-table-row {
                .ant-table-cell {
                    height: 48px;
                    padding: 6px 16px;
                }
            }
        }
    }

    &.ant-table-small {
        font-size: 13px;
        line-height: 22px;

        .ant-table-thead {
            .ant-table-cell {
                padding: 9px 16px;
            }
        }

        .ant-table-tbody {
            .ant-table-row {
                .ant-table-cell {
                    height: 40px;
                    padding: 5px 16px;
                }
            }
        }
    }

    .ant-table-column-sorters {
        padding: 0;

        .ant-table-column-sorter {
            color: rgba(0, 0, 0, 0.25);

            .ant-table-column-sorter-up.active,
            .ant-table-column-sorter-down.active {
                color: #0d2e3f;
            }
        }
    }

    .ant-table-filter-column {
        margin: 0;

        .ant-table-filter-column-title {
            padding: 0 16px 0 0;
        }

        .ant-table-filter-trigger-container {
            background: transparent !important;

            .ant-table-filter-trigger {
                color: rgba(0, 0, 0, 0.25);

                &.active {
                    color: #0d2e3f;
                }
            }
        }
    }

    &.ant-table-bordered {
        > .ant-table-container {
            border: none !important;
        }

        .ant-table-thead,
        .ant-table-tbody {
            .ant-table-cell {
                border: none !important;
            }
        }

        table {
            border: 1px solid #d9d9d9;
            box-shadow: none;
        }
    }
}

.table-2 {
    .ant-table {
        border-radius: 0;
        background: transparent;

        .ant-table-content {
            margin: 0 -6px;
        }

        table {
            padding: 0 6px;
            margin-top: -6px;
            border-spacing: 0 6px;
            border-radius: 0;
            box-shadow: none;
        }

        .ant-table-thead {
            tr {
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
            }

            .ant-table-cell {
                background-color: #f5f5f5;
                border-radius: 0;

                &:first-child {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                &:last-child {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }

                &.ant-table-column-has-sorters {
                    &:hover {
                        background: #f5f5f5;
                    }
                }

                &.ant-table-column-sort {
                    background: #f5f5f5;

                    &:hover {
                        background: #f5f5f5;
                    }
                }
            }
        }

        .ant-table-tbody {
            .ant-table-row {
                box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

                &:hover {
                    .ant-table-cell {
                        background-color: #f5f5f5;
                    }
                }

                .ant-table-cell {
                    background-color: #ffffff;

                    &:first-child {
                        border-top-left-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }

                    &:last-child {
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }

                    &.ant-table-column-sort {
                        background: #f5f5f5;
                    }
                }
            }

            .ant-table-placeholder {
                .ant-table-cell {
                    border-radius: 6px;
                    background: #ffffff;
                }

                &:hover {
                    .ant-table-cell {
                        background: #f5f5f5;
                    }
                }
            }
        }
    }
}

.table-3 {
    .ant-table {
        background: #f0f0f0;

        .ant-table-tbody {
            background: transparent;

            .ant-table-row {
                .ant-table-cell {
                    background-color: transparent;
                }

                &:hover {
                    .ant-table-cell {
                        background-color: #d9d9d9;
                    }
                }
            }

            .ant-table-placeholder {
                &:hover {
                    .ant-table-cell {
                        background: #d9d9d9;
                    }
                }
            }
        }
    }
}

.table-striped {
    .ant-table {
        .ant-table-tbody {
            .ant-table-row:nth-child(even) {
                background-color: #efefef;
            }
        }
    }
}

table.no-ant {
    color: rgba(0, 0, 0, 0.85);

    thead th {
        font-size: 11px;
        line-height: 20px;
        padding: 20px 16px;
    }

    tbody {
        tr:hover td {
            background-color: #f0f0f0;
        }
        td {
            height: 55px;
            padding: 10px 16px;
            transition: background 0.3s ease;
        }
    }

    &.stripped {
        tbody {
            tr:nth-child(odd) td {
                background-color: #f0f0f0;
            }
        }
    }

    &.bordered {
        border: 1px solid #d9d9d9;
        border-collapse: separate;
        border-spacing: 0;
        box-shadow: none;

        th {
            box-shadow: none;
        }
    }
}
