@media print {
    .pagebreak {
        display: block;
        break-after: always;
        background:red !important;
    }

    .print-gathering-event-container{
        page-break-inside: auto;
    }
}

.print-gathering-event-container {
    padding: 30px 20px;

    .icon {
        background: #f4f4f4;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-left: 20px;

        svg {
            color: #4f4f4f;
            font-size: 20px;
        }
    }

    .info {
        margin-left: 10px;
        font-size:12px;
    }

    .font-weight {
        font-weight: 500;
    }

    .icon-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: 15px;
    }
    
    .mt-5{
        margin-top: 5px;
    }

    .icon-top{
        svg{
            color: #4f4f4f;
            font-size: 30px;
        }

        &.smaller{
            svg{
                color: #4f4f4f;
                font-size: 20px;
                
            }

            
        }
    }
    .info-label{
        font-size: 10px;
    }
    .grid-icon{
        font-size: 14px;
    }
    .empty-square{
        width: 20px;
        height: 20px;
        border: 1px solid #d3d3d3;
        background:#fff;
    }

    .info-number{
        font-size: 40px
    }
}

.print-gathering-logo-box {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

   
}

.print-gathering-info {
    display: flex;
    margin-bottom: 50px;

    .print-gathering-date-location {
        flex: 2;
    }
}

.print-gathering-box {
    background: #f4f4f4;
    height: 100px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 12px;
}

.print-gathering-box + .print-gathering-box {
    margin-left: 20px;
}

.print-gathering-default-box {
    flex: 1;
}

.print-gathering-grid {
    font-size: 10px;
    width: 100%;

    tbody {
        background: #f4f4f4;
        display: flex;
        flex-direction: column;
    }

    .row {
        display: flex;
        background: #f4f4f4;
        border-left: 0.5px solid transparent;
        border-right: 0.5px solid transparent;
    }

    .row:nth-child(even) {
        background-color: #ffffff;
    }
    .row-header {
        display: flex;
        background-color: #ffffff;
        border-radius: 10px 10px 0 0;
        border: 0.5px solid #d3d3d3;
    }

    .col:nth-child(-n + 2),
    .col-header:nth-child(-n + 2),
    .col-header:last-child,
    .col:last-child {
        flex: 2;
    }

    .col {
        flex: 1;
        height: 25px;
        display: flex;
        align-items: center;
        border-right: 1px solid #d3d3d3;
        padding: 5px;
        word-break: break-all;
    }

    .col:last-child,
    .col-header:last-child {
        border-right: none;
    }

    .col-header {
        height: 25px;
        flex: 1;
        display: flex;
        align-items: center;
        font-weight: 500;
        text-transform: capitalize;
        padding: 5px;
        word-break: break-all;
        border-right: 1px solid #d3d3d3;
    }
}
