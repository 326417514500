.gathering-management-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	min-height: 100px;
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.event-info {
	flex: 3;
	display: flex;
	flex-direction: row;
}

.date-and-location {
	display: flex;
	flex: 4;
	margin-left: 15px;
}

.registred {
	display: flex;
	flex: 2;
}

.attendees {
	display: flex;
	flex: 2;
}

.date-and-location-info {
	// margin-left: 10px;
}

.info {
	margin-left: 10px;
}

.font-weight {
	font-weight: 500;
}

.info-number {
	font-size: 45px;
	font-weight: 700;
}

.info-label {
	font-size: 12px;
}

.management-actions {
	display: flex;
	flex: 1;
	justify-content: space-around;
	width: 100%;
}

.management-section {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	align-items: stretch;
	max-height: 70vh;
	height: 70vh;
}

.management-registred {
	flex: 3;
	width: 100%;
	height: 400px;
	padding: 20px;
	padding: 10px 20px 10px 0;
}

.management-selected {
	flex: 7;
	width: 100%;
	padding: 10px 0px 10px 20px;
	height: 300px;
}

.management-btn {
	padding: 12px;
	min-width: 90px;
	border-radius: 6px;
	box-sizing: border-box;
	cursor: pointer;
}

.management-btn-primary {
	background: #0f5172;
	color: #fff;
}

.management-btn-secondary {
	background: #fff;
	color: #0f5172;
	border: 1px solid #0f5172;
}

.gathering-management-title {
	font-size: 15px;
	text-transform: uppercase;
}

.icon {
	background: #f4f4f4;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;

	svg {
		color: #4f4f4f;
		font-size: 20px;
	}
}

.rounded-icon {
	background: #fff;
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;

	svg {
		font-size: 20px;
		cursor: pointer;
	}

	&.icon-bordered {
		border: 1px solid #0f5172;
		svg {
			color: #0f5172;
		}
	}

	&.background-filled {
		background: #0f5172;

		svg {
			color: #fff;
		}
	}
}

.gathering-management-close-details {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 18px;
	background: #fff;

	font-weight: bold;
	cursor: pointer;

	svg {
		color: #4f4f4f;
		font-size: 22px;
	}
}

.gathering-edit-event-conatiner {
	width: calc(100% - 20px);
	padding: 0 10px;
}

.edit-gathering-form {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.form-group {
		margin: 5px;
		width: calc(18% - 10px);
		display: flex;
		flex-direction: column;
		margin-right: 10px;
	}

	input {
		height: 30px;
		padding: 0;
		padding-left: 10px;
		min-height: 0;
	}
}

@media (max-width: 1000px) {
	.gathering-management-header {
		padding: 5px;
	}

	.management-section {
		flex-direction: column;
	}

	.event-info {
		flex-wrap: wrap;
		flex: 1 1 100%;
	}

	.date-and-location {
		width: 100%;
		flex: none;
		margin-left: 0;
		margin-bottom: 15px;
	}
	.gathering-management-conatiner {
		overflow-y: scroll;
	}
	.management-registred {
	}

	.management-selected {
		padding-left: 0;
		margin-top: 40px;
	}

	.management-actions {
		margin-right: 0;
		margin-top: 10px;
	}

	.action-container {
		.btn-action {
			padding: 10px 0;
		}
	}
}
