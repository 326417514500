
.check-info{
    border: 1px solid #6cad4f;
    background-color: #6cad4f;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
}

.text-with-info{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.text-with-info h3{
    color: #6cad4f;
    margin-top: 30px;
    margin-left: 20px; 
     font-size: 35px;
}
.qr-code-line{
    text-align: center;
    margin-top: 80px;
}

.instructions-pdf{
    text-align: left;
    margin-left: 10px;
    font-size: 20px;
    margin-top:50px;
}

.instructions-pdf > div::before {
    content: "•"; color: black ;
    margin-right: 10px;
}