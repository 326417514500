.gathering-confirm-general-background{
    background: #F5F5F5;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

.font-400{
    font-weight: 400;
}

    .gathering-confirm-footer{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size:12px;
        color:#999;

        img{
            width: 50px;
        }
    }
    
    

    .confirmation-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background:#fff;
        min-width: 400px;
        min-height:300px;
        border-radius:8px;
        padding:50px;

        img{
            margin-bottom: 50px;
        }
    }

    p{
        font-weight: 500;
    }

    svg{
        font-size:20px;
        margin-top:60px;
    }
}

