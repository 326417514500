.launcher-container {
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(25px);
    overflow: auto;
}


@media(max-width: 1500px){
    .form-group{
        width:90%;
    } 
}