/*text aling */
.position-in-line {
    font-size: 28px;
    margin-top: 60px;
}

.position{
    font-size: 80px;
    margin-top: 50px;
    padding: 52px;
}
.waiting-time{
    font-size: 17px;
    margin-top: 50px;
}

.margin-auto{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/*button */
.leave-line-button {
    background: #FF0000;
    border-radius: 6px;
    width: 90%;
    height: 73px;
    margin-left: 5px;
    margin-top: 70px;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

/*font-size on numbers */
.time{
    font-size: 30px;
}

.number-on-line{
    font-size: 115px;
}

/*text style */
.font-color{
    color: #4F4F4F;
    text-align: center;
    padding: 35px;
}