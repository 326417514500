body {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    line-height: 1.69;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
}

a {
    color: #2d9cdb;
}

a:hover,
a:focus,
a:visited {
    color: #096dd9;
}

h1,
.h1 {
    font-size: 15px;
    line-height: 24px;
}

h2,
.h2 {
    font-size: 13px;
    line-height: 22px;
}

h3,
.h3 {
    font-size: 11px;
    line-height: 20px;
}

h4,
.h4 {
    font-size: 9px;
    line-height: 18px;
}

h5,
.h5 {
    font-size: 7px;
    line-height: 16px;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fs-1 {
    font-size: 7px;
    line-height: 16px;
}

.fs-2 {
    font-size: 9px;
    line-height: 18px;
}

.fs-3 {
    font-size: 11px;
    line-height: 20px;
}

.fs-4 {
    font-size: 13px;
    line-height: 22px;
}

.fs-5 {
    font-size: 15px;
    line-height: 24px;
}

.fs-6 {
    font-size: 17px;
    line-height: 26px;
}

.fs-7 {
    font-size: 21px;
    line-height: 30px;
}

.strikethrough{
    text-decoration: line-through !important;
}

.underline {
    text-decoration: underline !important;
}

.captalize {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}