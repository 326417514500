.ant-card.cover-right {
    display: flex;
    flex-direction: row-reverse;

    .ant-card-cover,
    .ant-card-body {
        position: relative;
        flex: 0 1 50%;
    }

    .ant-card-body {
        padding-right: 8px;
    }
}

.ant-collapse {
    > .ant-collapse-item {
        > .ant-collapse-content {
            > .ant-collapse-content-box {
                padding: 6px 25px 8px 30px;
            }
        }
    }
}

.sites {
    width: 100%;
    padding-right: 8px;
    overflow-y: auto;

    .status {
        flex-shrink: 0;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: rgba(0, 0, 0, 0.45);
        border-radius: 50%;
    }

    .device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
    }
}

.map-caption {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    margin: 20px;
    background: #fff;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    z-index: 1;
    cursor: pointer;
}

.map-devices {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.site-activities {
    .ant-image {
        padding: 2px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        overflow: hidden;

        img {
            border-radius: 6px;
        }
    }
}

.bi.bi-diagram {
    box-shadow: none !important;
    overflow: auto !important;
}
