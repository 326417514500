.content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 100%;
}

.container>div {
    flex: 1 1 200px;
}


@media (max-width: 800px) {
    .content {
        justify-content: center;
    }

    
}