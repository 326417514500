.btn-people-counter {
	height: 54px;
	cursor: pointer;
	background: #fbfbfb;
	font-size: 25px;
}

.people-counter-display {
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 25px;
}

.company-name {
	width: 100%;
	margin-bottom: 30px;
	text-align: center;
	text-transform: capitalize;
}

.shared-text {
	margin-left: 10px;
	display: flex;
	align-items: center;
	font-size: 13px;
	color: #4f4f4f;
	font-weight: 400;
	font-family: "Montserrat-Light";
}

.select__value-container {
	background: #fbfbfb;
	border-radius: 6px;
}
.select__indicators,
.select__indicator-separator {
	background: #fbfbfb;
}

.people-number-warning {
	font-size: 12px;
	font-weight: 400;
	color: #808080;
	margin-bottom: 65px;
	align-self: start;
}
