.login-container {
	width: 260px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.apex-info {
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
}
